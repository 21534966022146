import style from "./Coaching.module.css";

const Ansatz = () => {
  return (
    <div className={style.content}>
      <div className={` ${style.contactBanner}`}>
        <img src="/image/me.jpeg"/>
      </div>
      <div className={style.dataWrapper}>
        <div className="container">
          <h1>Mein Coaching Ansatz</h1>
          <div className={style.contactWrapper}>
            In mein Coaching und meine Beratungen fließen neben meinen
            zahlreichen Ausbildungen auch jahrelange Selbsterfahrungen mit ein.
            Im Gegensatz zu anderen Coachingmethoden arbeite ich primär nicht
            zielorentiert, sondern suche mit dem Klienten*innen nach den
            persönlichen Ressourcen (Werten). Die Art des Coachings bietet an,
            gemeinsam neue Sichtweisen zu finden, um durch eine veränderte
            innere Haltung nachhaltige Entwicklungsschritte machen zu können.
            <br />
            <br /> Meine Themenschwerpunkte sind u.a.:
            <h3>
              - Erkennen und Überwinden mentaler und emotionaler Blockaden wie
              z.B.:
            </h3>
            <ul className={style.orderList}>
                <li> Selbstzweifel überwinden</li>
                <li> Selbstwertgefühl stärken</li>
                <li> Glaubenssätze erkennen</li>
                <li> Selbstbewusstsein stärken</li>
            </ul>
            <h3>- Schwierige Lebensumstände (z.B. Trennungssituation, Arbeitsplatzverlust etc.)</h3>
            <h3>- Leicht depressive Zustände</h3>
            <h3>- Burnout Symptome</h3>
            <h3>- Unterstützung bei wichtigen Entscheidungen z.B. Partnerschaft, Familie, Job etc.</h3>
            <div className={style.coachingButtons}>
            <a href="tel: +49 201 36456351">+49 201 36456351</a>
            <a href="mailto:beratung@dein-entwicklungsraum.de">beratung@dein-entwicklungsraum.de</a>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ansatz;
