import Hamburger from "hamburger-react";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { animateScroll } from "react-scroll";
import style from "./Header.module.css";

// import { RiArrowDropDownFill } from "react-icons/ri";

const Header = (props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={`${style.header} ${props.color}`}>
      <div className={`container flex ${style.desktopHeader}`}>
        <div>
          <Link
            to="/"
            className={style.logo}
            onClick={() => animateScroll.scrollToTop()}
          >
            {/* <img src={"/images/general/logo1.png"} alt="logo" loading="lazy" /> */}
            <p>XHANAN RADMANESCH</p>
          </Link>
        </div>
        <ul>
          <li onClick={() => animateScroll.scrollToTop()}>
            <NavLink to="/">Start</NavLink>
          </li>
          <li onClick={() => animateScroll.scrollToTop()}>
            <NavLink to="/meinePerson">Meine Person</NavLink>
          </li>

          <li onClick={() => animateScroll.scrollToTop()}>
            <NavLink to="/kontakt">Kontakt</NavLink>
          </li>
        </ul>
      </div>
      <div className={`${style.mobileNav} container`}>
        <div className={style.mobileDisplay}>
          <Link
            to="/"
            className={style.logo}
            onClick={() => {
              setOpen(false);
              animateScroll.scrollToTop();
            }}
          >
            <p>
              {" "}
              <p>XHANAN RADMANESCH</p>
            </p>
          </Link>
          <div className={style.mobileList}>
            <div className={style.mobileHeaderData}>
              <div className={`hamMenu ${style.hamburgerMenu}`}>
                <Hamburger toggled={isOpen} toggle={setOpen} size={23} />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${style.mobileMenuContainer} ${isOpen && style.opened}`}
        >
          <ul className="w-100 ">
            <li>
              <Link
                to="/"
                onClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }}
                className="colorChange"
              >
                Start
              </Link>
            </li>
            <li>
              <Link
                to="/meinePerson"
                onClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }}
              >
                Meine Person
              </Link>
            </li>

            <li>
              <Link
                to="/kontakt"
                onClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }}
              >
                Kontakt
              </Link>
            </li>
          </ul>
          {/* <img src="/images/general/circlem.svg"/> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
