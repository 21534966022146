import { useEffect, useState } from "react";
import style from "./Homepage.module.css";
import Work from "../../component/work/Work";
import AboutComponent from "../../component/about/About";

const Home = () => {
  let data = [
    {
      title: "Was ist Coaching?",
      desc: [
        "Professionelle, auf individuelle Bedürfnisse zugeschnittene Begleitung.",
        "Zielt auf persönliche und berufliche Veränderung ab.",
        "Fördert Lern- und Leistungsprozesse.",
      ],
      icon: "plant-leaf.png",
    },
    {
      title: "Verständnis von 'Systemisch'",
      desc: [
        "Ganzheitlicher persönlicher Entwicklungsprozess.",
        "Berücksichtigt alle relevanten Aspekte: Ziele, Emotionen, Selbstbild.",
        "Adressiert Hindernisse wie alte Glaubenssätze.",
      ],
      icon: "phoenix.png",
    },
    {
      title: "Mein Coaching-Ansatz",
      desc: [
        "Kombiniert umfangreiche Ausbildung und persönliche Erfahrungen.",
        "Fokussiert auf persönliche Ressourcen, nicht nur Ziele.",
        "Ermutigt zu neuen Perspektiven für nachhaltige Entwicklung.",
      ],
      icon: "phoenix.png",
    },
    {
      title: "Meine Themenschwerpunkte",
      desc: [
        "Überwindung mentaler und emotionaler Blockaden.",
        "Umgang mit Ängsten, Lebensherausforderungen, leichten Depressionen.",
        "Unterstützung bei wichtigen Entscheidungen in Partnerschaft, Familie, Beruf.",
      ],
      icon: "phoenix.png",
    },
  ];

  return (
    <>
      <div className="">
        <div className={style.banner}>
          <div className={style.overlayBanner}></div>
          <div className={style.bannerContent}>
            <h1>„Die längste Reise beginnt mit dem ersten Schritt‘‘ </h1>
            <p>- Laotse -</p>
          </div>
        </div>
        <div className="container">
          <AboutComponent />
          <Work />
        </div>
      </div>
    </>
  );
};

export default Home;
