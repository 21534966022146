import style from "./About.module.css";
import { Link, NavLink } from "react-router-dom";
import { animateScroll } from "react-scroll";
const AboutComponent = ({ showMore = false }) => {
  return (
    <>
      {!showMore && (
        <div className={style.aboutSection}>
          <div>
            <img src="/image/XhananImg.jpeg" />
          </div>
          <div className={style.aboutContent}>
            <p>
              Ich helfe Ihnen dabei, sich von Ängsten, Zweifeln und Stress zu
              befreien und Ihr volles Potenzial zu entfalten. In meiner Praxis
              schaffe ich einen sicheren Raum, in dem Sie wertschätzend und
              urteilsfrei behandelt werden. Wir finden gemeinsam neue Wege, um
              Ihre persönlichen und beruflichen Herausforderungen zu meistern.
            </p>
            <Link
              to="/kontakt"
              onClick={() => {
         
                animateScroll.scrollToTop();
              }}
              className={`${style.ptop} link`}
            >
              Kontakt aufnehmen
            </Link>
            {/* <NavLink to="/meinePerson">
              Erfahren Sie mehr über meine Fachkompetenz
            </NavLink> */}
          </div>
        </div>
      )}
      {showMore && (
        <>
          <div className={` ${style.biography}`}>
            <h2>Meine Person</h2>
            <div className={style.imageWrapper}>
              <img src="/image/bio.jpeg" />
            </div>

            <p>
              Ich bin zertifizierte psychologische Beraterin und systemischer
              Coach, habe mehrere Hundert Stunden Selbsterfahrung und -reflexion
              durchlaufen und befinde mich in regelmäßiger Supervision. Großen
              Wert lege ich zudem auf regelmäßige Fortbildungen.
            </p>
            <p>
              Ich begleite Menschen mit mentalen Veränderungswünschen auf ihrem
              Weg, sich von Ängsten, Zweifeln und Stress zu befreien und sich zu
              einem souveränen, ausbalancierten und selbstbewussten Denken und
              Verhalten hin zu verändern.
            </p>
            <p>
              Durch meine vielfältigen Erfahrungen und die Bewältigung von
              Herausforderungen lege ich großen Wert auf eine einfühlsame
              Haltung, um Menschen in ihren Veränderungsprozessen zu begleiten.
              Es bereitet mir große Freude und Erfüllung, Menschen die
              Möglichkeit zu geben, ihr eignes Potenzial zu erkennen und dabei
              hinderliche und einengende Gedankenmuster sowie negative
              Überzeugungen hinter sich lassen, so dass sie ihre Träume und ihr
              wahres Wollen und Sein verwirklichen können. Dies verlangt Mut,
              Vertrauen, Neugier, eine angemessene und bedachte
              Risikobereitschaft sowie innere Haltung und Stabilität. Ich habe
              an mir selbst erkannt und erlebt, dass die größten und
              entscheidenden Hürden in menschlichen Veränderungsprozessen meist
              in der eigenen Einstellung und Veränderungsbereitschaft liegen.
            </p>
            <p>
              Ich bin 1981 in Pristina geboren, aufgewachsen in Hofheim am
              Taunus, habe später dann in Frankfurt am Main gelebt und wohne nun
              seit 2019 in Essen NRW.
            </p>
            <p>
              In meiner Praxis begegne ich Ihnen wertschätzend, einfühlsam und
              vor allem urteilsfrei. Ich biete Ihnen einen sicheren Raum, über
              sich zu sprechen. Dabei ist es unwesentlich, ob die Ursachen im
              privaten oder beruflichen Bereich zu suchen sind. Gemeinsam suchen
              und finden wir neue Wege.
            </p>
          </div>
          <div className={style.experience}>
            <div>
              <h2>Meine Expertisen</h2>

              {/* <div className={style.imageWrapper}>
                <img src="/image/me.jpeg" />
              </div> */}
              <h3>Ausbildung</h3>
              <p>
                2021 - 2023 Zertifizierte AZAV Psychologische Beraterin an der
                Deutschen Heilpraktiker Schule in Mülheim an der Ruhr
              </p>
              <p>2023 Ausbildung zum Systemischen Coach und Beraterin</p>
              <p>
                2015 – 2017 Abschluss: NLP Practioner, DVNLP CNLPA Creative NLP
                Academy, Lehrtrainer Klaus Grochowiak, Wiesbaden
              </p>
              <p>
                2023 Mitglied im Verband Freier Psychotherapeuten,
                psychologischer Berater und Heilpraktiker für Psychotherapie
                (VFP)
              </p>
              <p>
                2011 Abschluss als Dipl. Betriebswirtin, Studienschwerpunkte:
                Controlling und Turnaround Management VWA an der Verwaltungs-
                und Wirtschafsakademie Frankfurt/Main
              </p>
              <p>
                2010 Abschluss als Eventmanagement-Ökonomin,
                Studienschwerpunkte: Event und Marketing VWA an der Verwaltungs-
                und Wirtschaftsakademien in Frankfurt/Main
              </p>
              <h3>Persönliche Selbsterfahrungen</h3>
              <p>
                2018 - 2019 Persönlichkeitsseminar Initiative, Charisma,
                Excellence Schwerpunkte: Selbstführung, Konfliktmanagement und
                Kooperation, Feedbackmethoden, Fremd- und Eigenwahrnehmung,
                Kommunikationspsychologie, Umgang mit Widerständen bei: neue
                wege Seminare, Baden-Baden
              </p>
              <p>
                2020 - 2023 Leadership und persönliche Kompetenz Ⅰ Schwerpunkte:
                Präsentation, Selbsterkenntnis, Prozessarbeit, Gesprächsführung,
                gruppendynamische Prozesse bei: neue wege Seminare, Baden-Baden
              </p>
              <p>
                2023 - voraussichtlich 2024 Leadership und persönliche Kompetenz
                ‖ Schwerpunkte: Vertiefung von Präsentation, Selbsterkenntnis,
                Prozessarbeit, Gesprächsführung, gruppendynamische Prozesse bei:
                neue wege Seminare, Baden-Baden
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AboutComponent;
