import React from "react";
import RouteConfig from "./router";
import Header from "./layout/header/Header";
import "./App.css";
import Footer from "./layout/footer/Footer";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
function App() {
  return (
    <>
      <Header />
      <div className="body">
        <RouteConfig />
        <div className="mobileIcon">
        <a href="tel: +49 201 36456351" > <img src="/image/phone.png"/></a>
        <Link to="/kontakt"     onClick={() => {
         
         animateScroll.scrollToTop();
       }} >
             <img src="/image/email.png"/>
            </Link>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default App;
