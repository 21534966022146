import style from "./Coaching.module.css";

const Systemich = () => {
  return (
    <div className={style.content}>
      <div className={` ${style.contactBanner}`}></div>
      <div className={style.dataWrapper}>
        <div className="container">
          <h1>Was versteht man unter „systemisch“?</h1>
          <div className={style.contactWrapper}>
            Als „systemisch“ bezeichnet man einen persönlichen
            Entwicklungsprozess, der alle relevanten Aspekte und Ebenen (Ziele,
            auftretende Probleme, Emotionen und das eigene Selbstbild)
            berücksichtigt, die ein Hindernis in dieser Entwicklung darstellen
            können:  <br /> <br />So etwa alte Glaubenssätze, die aus der Vergangenheit die
            Gegenwart und dann auch die Zukunft beeinflussen können und auf
            diese Weise das Erreichen persönlicher Entwicklungsziele verhindern
            oder zumindest erschweren.
            <div className={style.coachingButtons}>
            <a href="tel: +49 201 36456351">+49 201 36456351</a>
            <a href="mailto:beratung@dein-entwicklungsraum.de">beratung@dein-entwicklungsraum.de</a>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Systemich;
