import Hamburger from "hamburger-react";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { animateScroll } from "react-scroll";
import style from "./Footer.module.css";

const Footer = (props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <footer>
      <div className={style.footer}>
        <div className={`container ${style.footerDis}`}>
          <div>
            <NavLink
              to="/"
              onClick={() => {
                animateScroll.scrollToTop();
              }}
            >
              <img src="/image/logo.png" />
            </NavLink>
          </div>
          <div>
            <p>XHANAN RADMANESCH</p>
            <p>
              Frankenstraße 261
              <br />
              45134 Essen
            </p>
          </div>
          <div>
            <p>+49 201 36456351</p>
            <a href="mailto:beratung@dein-entwicklungsraum.de">
              beratung@dein-entwicklungsraum.de
            </a>
            <Link
              to="/anmeldungsformular"
              onClick={() => {
                animateScroll.scrollToTop();
              }}
            >
              Anmeldeformular
            </Link>
          </div>
        </div>
        <Link className={style.created}         onClick={() => {
                animateScroll.scrollToTop();
              }} to="impressum">
          Impressum | Datenschutzerklärung
        </Link>
        <div className={style.created}>©2023 XHANAN RADMANESCH.</div>
      </div>
    </footer>
  );
};

export default Footer;
