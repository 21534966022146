
import style from "./Impressum.module.css";

const Impressum = () => {
  return (
    <div className={style.content}>
      <div className={style.dataWrapper}>
        <div className="container">
          <h1>Impressum</h1>
          <div className={style.contactWrapper}>
            <h4>Angaben gemäß § 5 TMG</h4>
            <p>XHANAN RADMANESCH</p>
            <p>Frankenstraße 261 45134 Essen</p>
            <h4>Kontakt</h4>
            <p>Telefon: +49 201 36456351</p>
            <p>E-Mail: beratung@dein-entwicklungsraum.de</p>
            <h4>Haftung für Inhalte</h4>
            <p>
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
              Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
              verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
              jedoch nicht 2 / 4 verpflichtet, übermittelte oder gespeicherte
              fremde Informationen zu überwachen oder nach Umständen zu
              forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
              <br />
              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
              Informationen nach den allgemeinen Gesetzen bleiben hiervon
              unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
              Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
              Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte umgehend entfernen.
            </p>
            <h4>Haftung für Links</h4>
            <p>
              Unser Angebot enthält Links zu externen Websites Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
              der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
            <h4>Urheberrecht</h4>
            <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
              Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.
            </p>
          </div>
        </div>
        <div className="container">
          <h1>Datenschutzerklärung</h1>
          <div className={style.contactWrapper}>
            <h4>Präambel</h4>
            <p>
              Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
              aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
              auch kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in
              welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle
              von uns durchgeführten Verarbeitungen personenbezogener Daten,
              sowohl im Rahmen der Erbringung unserer Leistungen als auch
              insbesondere auf unseren Webseiten, in mobilen Applikationen sowie
              innerhalb externer Onlinepräsenzen, wie z. B. unserer
              Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als
              "Onlineangebot"). Die verwendeten Begriffe sind nicht
              geschlechtsspezifisch.
            </p>
            <ul>
              <li>Präambel</li>
              <li>Verantwortlicher</li>
              <li>Übersicht der Verarbeitungen</li>
              <li>Maßgebliche Rechtsgrundlagen</li>
              <li>Internationale Datentransfers</li>
              <li>Bereitstellung des Onlineangebotes und Webhosting</li>
              <li>Kontakt- und Anfragenverwaltung</li>
            </ul>
            <h4>Verantwortlicher</h4>
            <p>XHANAN RADMANESCH</p>
            <p>Frankenstraße 261 45134 Essen</p>
            <p>+49 201 36456351</p>
            <p>beratung@dein-entwicklungsraum.de</p>
            <h4>Übersicht der Verarbeitungen</h4>
            <p>
              Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten
              und die Zwecke ihrer Verarbeitung zusammen und verweist auf die
              betroffenen Personen. Arten der verarbeiteten Daten
            </p>
            <ul>
              <li>Kontaktdaten</li>
              <li>Anmeldungsformular</li>
            </ul>
            Zwecke der Verarbeitung
            <ul>
              <li>Kontaktanfragen und Kommunikation.</li>
              <li>Verwaltung und Beantwortung von Anfragen.</li>
            </ul>
            <h4>Maßgebliche Rechtsgrundlagen</h4>
            <p>
              Maßgebliche Rechtsgrundlagen nach der DSGVO: Im Folgenden erhalten
              Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis
              wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur
              Kenntnis, dass neben den Regelungen der DSGVO nationale
              Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland
              gelten können. Sollten ferner im Einzelfall speziellere
              Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der
              Datenschutzerklärung mit.
            </p>
            <ul>
              <li>
                Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO) - Die betroffene
                Person hat ihre Einwilligung in die Verarbeitung der sie
                betreffenden personenbezogenen Daten für einen spezifischen
                Zweck oder mehrere bestimmte Zwecke gegeben.
              </li>
              <li>
                Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S.
                1 lit. b) DSGVO) - Die Verarbeitung ist für die Erfüllung eines
                Vertrags, dessen Vertragspartei die betroffene Person ist, oder
                zur Durchführung vorvertraglicher Maßnahmen erforderlich, die
                auf Anfrage der betroffenen Person erfolgen.
              </li>
              <li>
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO) - Die
                Verarbeitung ist zur Wahrung der berechtigten Interessen des
                Verantwortlichen oder eines Dritten erforderlich, sofern nicht
                die Interessen oder Grundrechte und Grundfreiheiten der
                betroffenen Person, die den Schutz personenbezogener Daten
                erfordern, überwiegen.
              </li>
            </ul>
            <p>
              Nationale Datenschutzregelungen in Deutschland: Zusätzlich zu den
              Datenschutzregelungen der DSGVO gelten nationale Regelungen zum
              Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz
              zum Schutz vor Missbrauch personenbezogener Daten bei der
              Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG
              enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum
              Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung
              besonderer Kategorien personenbezogener Daten, zur Verarbeitung
              für andere Zwecke und zur Übermittlung sowie automatisierten
              Entscheidungsfindung im Einzelfall einschließlich Profiling.
              Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer
              zur Anwendung gelangen.
            </p>
            <h1>Internationale Datentransfers</h1>
            <p>
              Datenverarbeitung in Drittländern: Sofern wir Daten in einem
              Drittland (d. h., außerhalb der Europäischen Union (EU), des
              Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die
              Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter
              oder der Offenlegung bzw. Übermittlung von Daten an andere
              Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur
              im Einklang mit den gesetzlichen Vorgaben. Sofern das
              Datenschutzniveau in dem Drittland mittels eines
              Angemessenheitsbeschlusses anerkannt wurde (Art. 45 DSGVO), dient
              dieser als Grundlage des Datentransfers. Im Übrigen erfolgen
              Datentransfers nur dann, wenn das Datenschutzniveau anderweitig
              gesichert ist, insbesondere durch Standardvertragsklauseln (Art.
              46 Abs. 2 lit. c) DSGVO), ausdrückliche Einwilligung oder im Fall
              vertraglicher oder gesetzlich erforderlicher Übermittlung (Art. 49
              Abs. 1 DSGVO). Im Übrigen teilen wir Ihnen die Grundlagen der
              Drittlandübermittlung bei den einzelnen Anbietern aus dem
              Drittland mit, wobei die Angemessenheitsbeschlüsse als Grundlagen
              vorrangig gelten. Informationen zu Drittlandtransfers und
              vorliegenden Angemessenheitsbeschlüssen können dem
              Informationsangebot der EU-Kommission entnommen werden:{" "}
              <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de.">
                https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de.
              </a>
            </p>
            <h4>Kontakt- und Anfragenverwaltung</h4>
            <p>
              Bei der Kontaktaufnahme mit uns (z. B. per Post, Kontaktformular,
              E-Mail, Telefon oder via soziale Medien) sowie im Rahmen
              bestehender Nutzer- und Geschäftsbeziehungen werden die Angaben
              der anfragenden Personen verarbeitet soweit dies zur Beantwortung
              der Kontaktanfragen und etwaiger angefragter Maßnahmen
              erforderlich ist. Verarbeitete Datenarten: Kontaktdaten (z. B.
              E-Mail, Telefonnummern); Inhaltsdaten (z. B. Eingaben in
              Onlineformularen); Nutzungsdaten (z. B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations- und
              Verfahrensdaten (z. .B. IP-Adressen, Zeitangaben,
              Identifikationsnummern, Einwilligungsstatus). Betroffene Personen:
              Kommunikationspartner. Zwecke der Verarbeitung: Kontaktanfragen
              und Kommunikation; Verwaltung und Beantwortung von Anfragen;
              Feedback (z. B. Sammeln von Feedback via Online-Formular).
              Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
              Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f) DSGVO). Vertragserfüllung und vorvertragliche Anfragen (Art. 6
              Abs. 1 S. 1 lit. b) DSGVO). Weitere Hinweise zu
              Verarbeitungsprozessen, Verfahren und Diensten: Kontaktformular:
              Wenn Nutzer über unser Kontaktformular, E-Mail oder andere
              Kommunikationswege mit uns in Kontakt treten, verarbeiten wir die
              uns in diesem Zusammenhang mitgeteilten Daten zur Bearbeitung des
              mitgeteilten Anliegens; Rechtsgrundlagen: Vertragserfüllung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO). ​
            </p>
            <p>
              Created with the free Data Protection Generator.de by Dr. Thomas
              Schwenke
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
