import style from "./Ammelde.module.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { useEffect, useState } from "react";
import Map from "../../component/map/Map";
import Mail from "../../component/mail/Mail";
const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Dieses Feld ist erforderlich"),
  address: Yup.string().required("Dieses Feld ist erforderlich"),
  phoneNumber: Yup.string().required("Dieses Feld ist erforderlich"),
  email: Yup.string().email("Invalid email").required("Dieses Feld ist erforderlich"),
  topicDescription: Yup.string().required("Dieses Feld ist erforderlich"),
  topicDescription2: Yup.string().required("Dieses Feld ist erforderlich"),
});

const YourFormComponent = () => {
  const [success, setSuccess] = useState({
    isSuccess: false,
    msg: "",
  });
  const handleSubmit = async (values, { resetForm }) => {
    let sendMail = await axios.post(
      "https://www.dein-entwicklungsraum.de/api/ammelde",
      values, {
        timeout: 5000  
      }
    );
    if (sendMail.data == "success") {
      setSuccess({
        isSuccess: true,
        msg: "Kontaktinformationen erfolgreich gesendet",
      });
      resetForm();
    } else
    setSuccess({
      isSuccess: false,
      msg: "Es ist ein Problem aufgetreten, bitte versuchen Sie es später noch einmal",
    });
  };
  return (
    <>
      <div className={` ${style.contactBanner}`}></div>
      {!success.isSuccess ? (
      <div className={`container`}>
        <div className={style.contact}>
          <div>
            <h1>Personendaten</h1>
            <Formik
              initialValues={{
                fullName: "",
                address: "",
                phoneNumber: "",
                email: "",
                topicDescription: "",
                topicDescription2: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className={style.AmmeldeName}>
                    <div>
                      <label htmlFor="fullName">Name, Vorname:</label>
                      <Field
                        type="text"
                        id="fullName"
                        name="fullName"
                        className={
                          errors.fullName && touched.fullName && style.error
                        }
                      />
                    </div>
                    <div>
                      <label htmlFor="address">Adresse:</label>
                      <Field
                        type="text"
                        id="address"
                        name="address"
                        className={
                          errors.address && touched.address && style.error
                        }
                      />
                    </div>
                  </div>
                  <div className={style.AmmeldeName}>
                    <div>
                      <label htmlFor="phoneNumber">Telefonnummer:</label>
                      <Field
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        className={
                          errors.phoneNumber &&
                          touched.phoneNumber &&
                          style.error
                        }
                      />
                    </div>
                    <div>
                      <label htmlFor="email">E-Mail:</label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        className={errors.email && touched.email && style.error}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="topicDescription">
                      Beratungsgrund und -ziel: <br/>  Beschreiben Sie bitte kurz, welches Thema Sie mit mir bearbeiten wollen!
                    </label>
                    <Field
                      as="textarea"
                      id="topicDescription"
                      name="topicDescription"
                      className={
                        errors.topicDescription &&
                        touched.topicDescription &&
                        style.error
                      }
                    />
                    <label>
                      Können Sie schon kurz angeben, welches Ergebnis Sie mit
                      meiner Unterstützung erzielen wollen?
                    </label>
                    <Field
                      as="textarea"
                      id="topicDescription2"
                      name="topicDescription2"
                      className={
                        errors.topicDescription2 &&
                        touched.topicDescription2 &&
                        style.error
                      }
                    />
                  </div>
                  {/* <div>
                    <label htmlFor="disclaimer">Haftungsausschluss:</label>
                    <Field as="textarea" id="disclaimer" name="disclaimer" />
                    {errors.disclaimer && touched.disclaimer && (
                      <div className={style.error}>{errors.disclaimer}</div>
                    )}
                  </div> */}
                  <div className={style.disclaimer}>
                    <h4>Haftungsausschluss</h4>
                    <p>
                      Ich nehme zur Kenntnis, dass das Coaching eine ärztliche
                      Behandlung nicht ersetzt und mit keinem
                      Heilungsversprechen verbunden ist. Mir ist außerdem
                      bewusst, dass ein Erfolg auch von mir selbst abhängt, der
                      Coach mir einen solchen daher nicht garantieren kann.
                    </p>
                    <p>
                      Entschädigung bei Nichterscheinen - bzw. kurzfristiger
                      Terminabsage
                    </p>
                    <p>
                      Bitte beachten Sie, dass Terminabsagen bis 48 h vor dem
                      vereinbarten Termin kostenfrei sind. Bei Absagen von
                      weniger als 48 h (Wochenenden und Feiertage nicht
                      eingerechnet) vor dem Termin wird der volle Betrag einer
                      Sitzung in Rechnung gestellt, sollte ich den Termin nicht
                      anderweitig vergeben können.
                    </p>
                    <p>
                      Daher bitte ich um respektvollen Umgang mit unserer Zeit.
                    </p>
                  </div>
                  {success.msg.length != 0 && <p>{success.msg}</p>}
                  <button type="submit">Absenden</button>
                </Form>
              )}
            </Formik>
          </div>
          <div className={style.advice}>
            <div className={style.contactIcon}>
              {/* <img src={`/image/icons/email.png`} width={50} /> */}
              <div>
              <p className={style.main}>XHANAN RADMANESCH</p>
              <div className={style.iconDis}>
                  <img src={`/image/icons/mail.png`} width={50} />
                  <p>beratung@dein-entwicklungsraum.de</p>
                  </div>
              </div>
            </div>
            <div className={style.contactIcon}>
              {/* <img src={`/image/icons/phone-call.png`} width={50} /> */}
              <div className={style.iconDis}>
                <img src={`/image/icons/telephone.png`} width={50} />
                  <p>+49 201 36456351</p>
                </div>
            </div>
            <div className={style.contactIcon}>
              {/* <img src={`/image/icons/location.png`} width={50} /> */}
              <div className={style.iconDis}>
                  <img src={`/image/icons/loc.png`} width={50} />
                  <p>
                    Frankenstraße 261,
                  
                    45134 Essen
                  </p>
                  </div>
            </div>
            <div
            >
              <Map />
              <a
                class="google-map-html"
                href="https://www.bootstrapskins.com/themes"
                id="make-map-data"
              >
                premium bootstrap themes
              </a>
              <style></style>
            </div>
          </div>
        </div>
      </div>): <div className={style.messageWrapper}>
          <Mail />
          <p>Kontaktinformationen erfolgreich gesendet</p>
        </div>}
    </>
  );
};

export default YourFormComponent;
