
import AboutComponent from "../../component/about/About";
import style from "./About.module.css";
import { Link, NavLink } from "react-router-dom";
const About = () => {
  let data = [
    {
      title: "Was ist Coaching?",
      desc: [
        "Professionelle, auf individuelle Bedürfnisse zugeschnittene Begleitung.",
        "Zielt auf persönliche und berufliche Veränderung ab.",
        "Fördert Lern- und Leistungsprozesse.",
      ],
    },
    {
      title: "Verständnis von 'Systemisch'",
      desc: [
        "Ganzheitlicher persönlicher Entwicklungsprozess.",
        "Berücksichtigt alle relevanten Aspekte: Ziele, Emotionen, Selbstbild.",
        "Adressiert Hindernisse wie alte Glaubenssätze.",
      ],
    },
    {
      title: "Mein Coaching-Ansatz",
      desc: [
        "Kombiniert umfangreiche Ausbildung und persönliche Erfahrungen.",
        "Fokussiert auf persönliche Ressourcen, nicht nur Ziele.",
        "Ermutigt zu neuen Perspektiven für nachhaltige Entwicklung.",
      ],
    },
    {
      title: "Meine Themenschwerpunkte",
      desc: [
        "Überwindung mentaler und emotionaler Blockaden.",
        "Umgang mit Ängsten, Lebensherausforderungen, leichten Depressionen.",
        "Unterstützung bei wichtigen Entscheidungen in Partnerschaft, Familie, Beruf.",
      ],
    },
  ];

  return (
    <div className={style.about}>
      {/* <div className={style.banner}>
        <div className={style.overlayBanner}></div>
        <h1>TO MY PERSON</h1>
      </div> */}

      <div className={`container `}>
        <div className={`${style.aboutWrapper}`}>
          <AboutComponent showMore={true} />
        </div>
      </div>
    </div>
  );
};

export default About;
