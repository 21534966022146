import style from "./Coaching.module.css";

const Coaching = () => {
  return (
    <div className={style.content}>
      <div className={` ${style.contactBanner}`}></div>
      <div className={style.dataWrapper}>
        <div className="container">
          <h1>Was ist „Coaching“?</h1>
          <div className={style.contactWrapper}>
            Coaching ist eine professionelle Begleitung, die zeitlich begrenzt
            und auf die individuellen Bedürfnisse des/der Klienten/in abgestimmt
            ist.<br/><br/> Ziel ist es, persönliche und berufliche Veränderungen zu
            erreichen und eigene Lern- und Leistungsprozesse weiterzuentwickeln.
            <div className={style.coachingButtons}>
            <a href="tel: +49 201 36456351">+49 201 36456351</a>
            <a href="mailto:beratung@dein-entwicklungsraum.de">beratung@dein-entwicklungsraum.de</a>
          </div>
          </div>
       
        </div>
      </div>
    </div>
  );
};

export default Coaching;
