import style from "./OnlineCoaching.module.css";

const OnlineCoaching = () => {
  return (
    <div className={style.content}>
      <div className={` ${style.contactBanner}`}>
        <img src="/image/rred.png"/>
      </div>
      <div className={style.dataWrapper}>
        <div className="container">
          <h1>Online-Coaching</h1>
          <div className={style.contactWrapper}>
          Meine Angebote richten sich auch an Menschen, die gerne flexibel bleiben möchten und bequem von zu Hause aus Unterstützung suchen. Ich biete Ihnen die Möglichkeit, Sie auch virtuell zu unterstützen, z. B. über Plattformen wie Zoom sowie telefonische Beratungen. Diese Option ermöglicht es Ihnen, Unterstützung von überall aus zu erhalten, damit Sie bestmöglich unterstützt werden können.

            <br />
            <br />Vereinbaren Sie gerne ein 30-minütiges Kennenlerngespräch und machen Sie den ersten Schritt zu mehr mentaler Gesundheit und Zufriedenheit.
<br/><br/>
Ich freue mich darauf, Sie kennenzulernen.
           
            <div className={style.coachingButtons}>
            <a href="tel: +49 201 36456351">+49 201 36456351</a>
            <a href="mailto:beratung@dein-entwicklungsraum.de">beratung@dein-entwicklungsraum.de</a>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineCoaching;
