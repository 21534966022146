import mail from "../../assets/mailsend.json";
import { useLottie } from "lottie-react";

const Mail = () => {
  const options = {
    animationData: mail,
    loop: true,
  };

  const { View } = useLottie(options);
  return <>{View}</>;
};

export default Mail;
