import style from "./Map.module.css";


const Map = () => {
 
  return (
    <>
      <a target="" href="https://maps.app.goo.gl/zD4YLhztxQxKfit99" className={style.myMapCanva}>
        <div className={style.boxInfo}>
          <span>XHANAN RADMANESCH | Dein Entwicklungsraum</span>
          <a target="" href="https://maps.app.goo.gl/zD4YLhztxQxKfit99">
          Richtung
          </a>
        </div>
        <iframe
          style={{
            border: 0,
            width: "100%",
            height: "300px",
            pointerEvents: "none",
          }}
          frameborder="0"
          scrolling="no"
          allowFullScreen
          src="https://www.google.com/maps/embed/v1/place?q=Frankenstraße+261,+45134+Essen,+Germany&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
        ></iframe>
      </a>
    </>
  );
};

export default Map;
