import style from "./Contact.module.css";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import axios from "axios";
import Map from "../../component/map/Map";
import Mail from "../../component/mail/Mail";

const Contact = () => {
  const [success, setSuccess] = useState({
    isSuccess: false,
    msg: "",
  });
  const SignupSchema = Yup.object({
    firstName: Yup.string().required("Dieses Feld ist erforderlich"),
    lastName: Yup.string().required("Dieses Feld ist erforderlich"),
    desc: Yup.string().required("Dieses Feld ist erforderlich"),
    email: Yup.string()
      .email("Ungültige E-Mail-Adresse")
      .required("Dieses Feld ist erforderlich"),
    validInput: Yup.boolean().oneOf([true], "Dieses Feld ist erforderlich"),
  });

  return (
    <>
      <div className={` ${style.contactBanner}`}></div>
      {!success.isSuccess ? (
        <div className={`container`}>
          <div className={style.contact}>
            <Formik
              validationSchema={SignupSchema}
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                desc: "",
                validInput: false,
              }}
              onSubmit={async (values, actions, resetForm) => {
                let sendMail = await axios.post(
                  "https://www.dein-entwicklungsraum.de/api/kontakt",
                  values, {
                    timeout: 5000  
                  }
                );
                if (sendMail.data == "success") {
                  setSuccess({
                    isSuccess: true,
                    msg: "Kontaktinformationen erfolgreich gesendet",
                  });
                  resetForm();
                } else
                  setSuccess({
                    isSuccess: false,
                    msg: "Es ist ein Problem aufgetreten, bitte versuchen Sie es später noch einmal",
                  });
              }}
            >
              {({ values, handleChange, handleBlur, touched, errors }) => (
                <Form>
                  <h2>Kontakt aufnehmen</h2>
                  <div className={style.input}>
                    <label htmlFor="firstName">Vorname</label>
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    {touched.firstName && errors.firstName ? (
                      <span className={style.error}>{errors.firstName}</span>
                    ) : null}
                  </div>
                  <div className={style.input}>
                    <label htmlFor="lastName">Nachname</label>
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    {touched.lastName && errors.lastName ? (
                      <span className={style.error}>{errors.lastName}</span>
                    ) : null}
                  </div>
                  <div className={style.input}>
                    <label htmlFor="firstName">E-Mail-Adresse</label>
                    <input
                      id="firstName"
                      name="email"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {touched.email && errors.email ? (
                      <span className={style.error}>{errors.email}</span>
                    ) : null}
                  </div>
                  <div className={style.input}>
                    <label htmlFor="desc">Nachricht schreiben</label>
                    <textarea
                      name="desc"
                      onChange={handleChange}
                      value={values.desc}
                    ></textarea>
                    {touched.desc && errors.desc ? (
                      <span className={style.error}>{errors.desc}</span>
                    ) : null}
                  </div>
                  <div className={style.checkBox}>
                    <input
                      onChange={handleChange}
                      value={values.validInput}
                      type="checkbox"
                      id="box"
                      name="validInput"
                    />
                    <label htmlFor="box">
                      Hiermit bestätige ich die Datenschutzhinweise: Ich erkläre
                      mich einverstanden, dass meine in das Kontaktformular
                      eingegebenen Daten elektronisch gespeichert und zum Zweck
                      der Kontaktaufnahme verarbeitet und genutzt werden. Mir
                      ist bekannt, dass ich meine Einwilligung jederzeit
                      widerrufen kann. Siehe auch unsere Datenschutzerklärung.{" "}
                    </label>
                  </div>
                  {touched.validInput && errors.validInput ? (
                    <span className={style.error}>{errors.validInput}</span>
                  ) : null}
                  {success.msg.length != 0 && <p>{success.msg}</p>}
                  <button type="submit" className="link">
                    Absenden
                  </button>
                </Form>
              )}
            </Formik>
            <div className={style.advice}>
              <div className={style.contactIcon}>
      
                <div>
                  <p className={style.main}>XHANAN RADMANESCH</p>
                  <div className={style.iconDis}>
                  <img src={`/image/icons/mail.png`} width={50} />
                  <p>beratung@dein-entwicklungsraum.de</p>
                  </div>
                
                </div>
              </div>
              <div className={style.contactIcon}>
              
                <div className={style.iconDis}>
                <img src={`/image/icons/telephone.png`} width={50} />
                  <p>+49 201 36456351</p>
                </div>
              </div>
              <div className={style.contactIcon}>
              
                <div>
                  <div className={style.iconDis}>
                  <img src={`/image/icons/loc.png`} width={50} />
                  <p>
                    Frankenstraße 261,
                  
                    45134 Essen
                  </p>
                  </div>
                  <Map />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={style.messageWrapper}>
          <Mail />
          <p>Kontaktinformationen erfolgreich gesendet</p>
        </div>
      )}
    </>
  );
};

export default Contact;
