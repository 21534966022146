import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../page/home/Homepage";
import Contact from "../page/contact/Contact";
import YourFormComponent from "../page/ammelde/Ammelde";
import Coaching from "../page/coaching/Coaching";
import Systemich from "../page/systemich/Systemich";
import Ansatz from "../page/ansatz/Ansatz";
import Impressum from "../page/impressum/Impressum";
import About from "../page/about/About";
import OnlineCoaching from "../page/onlineCoaching/OnlineCoaching";
function RouteConfig() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/meinePerson" element={<About />} />
      <Route path="/kontakt" element={<Contact />} />
      <Route path="/coaching" element={<Coaching />} />
      <Route path="/anmeldungsformular" element={<YourFormComponent />} />
      <Route path="/systemisch" element={<Systemich />} />
      <Route path="/coachingAnsatz" element={<Ansatz />} />
      <Route path="/impressum" element={<Impressum />} />
      <Route path="/onlineCoaching" element={<OnlineCoaching />} />

      <Route path="/*" element={<Home />} />
    </Routes>
  );
}

export default RouteConfig;
