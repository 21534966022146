import React from "react";
import style from "./Work.module.css";
import { Link } from "react-router-dom";import { animateScroll } from "react-scroll";
const Work = () => {
  const work = [
    {
      image: "/image/bb.jpg",
      text: "Was ist „Coaching“?",
      link: '/coaching'
    },
    {
      image: "/image/worktwo.jpg",
      text: "Online-Coaching",
      link: '/onlineCoaching'
    },
    {
      image: "/image/b2.jpg",
      text: "Was versteht man unter „systemisch“?",
      link: '/systemisch'
    },
    {
      image: "/image/bb2.jpg",
      text: "Mein Coaching Ansatz",
      link: '/coachingAnsatz'
    },
 
  ];
  return (
    <div className={` ${style.containerWrapper}`}>
      <div className="section">
        <h2>
           Einblicke in mein Coaching
        </h2>
      </div>
      <div className={style.workDis}>
        {work.map((item) => {
          return (
            <Link to={item.link}    onClick={() => {
              
                animateScroll.scrollToTop()
              }}>
              <img src={item.image} />
              <p>{item.text}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Work;
